import { addGlobalContexts, removeGlobalContexts } from "@snowplow/browser-tracker";
import { Tv2NoLivesport, createTv2NoLivesport, trackLivesportUserActionSpec, Tv2NoUserAction, Tv2User, createTv2User, Tv2NoLivesportFollow, createTv2Component, Tv2Component, trackTv2NoLivesportFollow, trackFollowFavouriteActionSpec } from "../../snowtype/snowplow";
import { Logger } from "../helpers/logger";
import { ILivesportTracker } from "./ILivesportTracker";

export class LivesportTracker implements ILivesportTracker {
  private currentContexts: ReturnType<typeof createTv2NoLivesport | typeof createTv2User>[] = [];

  setContext(data: Tv2NoLivesport, user: Tv2User): void {
    try {
      if (this.currentContexts.length > 0) {
        removeGlobalContexts(this.currentContexts);
      }
      this.currentContexts = [createTv2NoLivesport(data), createTv2User(user)];
      addGlobalContexts(this.currentContexts);
    } catch (error) {
      Logger.error("Failed to set global contexts in LivesportTracker:", error);
    }
  }

  trackFollowAction(data: Tv2NoLivesportFollow): void {
    const { intention } = data;
    try {
      trackFollowFavouriteActionSpec(data);
      Logger.debug(`Livesport ${intention} event tracked`);
    } catch (error) {
      Logger.error(`Error tracking livesport ${intention} event:`, error);
    }
  }

  trackUserAction(data: Tv2NoUserAction, context: Tv2Component): void {
    try {
      const componentContext = createTv2Component(context);
      trackLivesportUserActionSpec( {
        ...data,
        context: [componentContext],
      });
      Logger.debug(`Livesport user action event tracked`);
    } catch (error) {
      Logger.error(`Error tracking livesport user action event:`, error);
    }
  }
}
